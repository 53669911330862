// React
import React from "react";
import UserListEdit from "../../../containers/UserList/EditUserList";
import UserLogged from "../../../containers/userLogged";

const CreateUserList = () => {
  return (
    <UserLogged>
      <UserListEdit returnUrl={"/ul"} />
    </UserLogged>
  );
};

export default CreateUserList;
